@import '../../styles/propertySets.css';

.root {
  & p {
    @apply --marketplaceH4FontStyles;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }
  & li {
    list-style: disc inside;
  }
  & table {
    width: 100%;
    border: 1px solid rgba(50, 69, 70, .12);
    text-align: left;
  }
  & tr {
    border: 1px solid rgba(50, 69, 70, .12);
  }
  & td {
    padding: 10px 14px 8px;
    border-right: 1px solid rgba(50, 69, 70, .12);

  }
  & th {
    min-height: 41px;
    padding: 10px 14px 8px;
    border-right: 1px solid rgba(50, 69, 70, .12);
  }
  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }
}
